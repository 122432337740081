import React, {useEffect, useState} from "react";
import {Container, Modal} from "react-bootstrap";
import * as PropTypes from "prop-types";
import Post from "./Post";
import PostFeedItem from "./PostFeedItem";
import Filters from "./Filters";
import {useAuth} from "../util/auth";
import Slideshow from "./Slideshow";
import PostListHeader from "./PostListHeader";

Post.propTypes = {posts: PropTypes.any};

function Posts(props) {
  const { posts, categories, title, withSlideShow } = props
  console.log('posts', posts)
  console.log('categories', categories)
  const auth = useAuth()
  const [ includedCategories, setIncludedCategories ] = useState(categories)
  const [ filter, setFilter ] = useState('all')
  const [ filteredPosts, setFilteredPosts ] = useState(posts)

  useEffect(() => {
    if(posts && categories && posts.length > 0 && categories.length > 0) {
      const postCategories = posts.map(p => p.category)
      const result = categories
        .filter(c => postCategories.includes(c.id))
      setIncludedCategories(result)
    }
  }, [posts, categories])

  useEffect(() => {
    if (filter) {
      const blockFiltered = auth?.user?.blocked ?
        posts.filter(p => !auth.user.blocked.includes(p.owner)) : posts
      setFilteredPosts(filter === 'all' ? blockFiltered : blockFiltered.filter(p => p.category === filter))
    }
  }, [filter, posts, auth])

  const filterPosts = f => () => setFilter(f)

  const [ show, setShow ] = useState(false)
  const handleClose = e => {
    setShow(false)
  }

  return (
    <Container className={"mt-1"}>
      <PostListHeader title={title} withSlideShow={withSlideShow} onClick={() => setShow(true)} />
      {includedCategories &&
        <Filters activeFilter={filter} includedCategories={includedCategories}
                 filterPosts={filterPosts}/>
      }
      {filteredPosts && filteredPosts.map(post => (<PostFeedItem key={post.id} post={post}/>))}
      {withSlideShow && filteredPosts && <Modal
        show={show}
        size={"xl"}
        onHide={handleClose}
        backdrop={true}
        keyboard={true}
        scrollable={false}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{position: "relative", height: "80vh"}}>
            <Slideshow posts={filteredPosts}/>
        </Modal.Body>
      </Modal>}
    </Container>
  )
}

export default Posts;
