import React, {useEffect, useState} from "react";
import AddCollection from "./AddCollection";
import {addPostToCollection, removePostFromCollection, useCollections} from "../util/db";
import {Modal, Alert, Button, Col, ListGroup, Row} from "react-bootstrap";
import {BsFillBagXFill, BsFillSave2Fill} from "react-icons/bs";
import CollectionThumb from "./CollectionThumb";

const CollectionsModal = props => {
  const { show, handleClose, user, postId, setShow } = props
  const [ existingCollections, setExistingCollections ] = useState([])
  const [ availableCollections, setAvailableCollections ] = useState([])
  const { data: collections } = useCollections(user)

  useEffect(() => {
    if (collections) {
      setAvailableCollections(collections.filter(c => !c.posts.includes(postId)))
      setExistingCollections(collections.filter(c => c.posts.includes(postId)))
    }
  }, [collections, postId])

  const addToCollection = collectionId => e => {
    addPostToCollection(postId, collectionId)
  }

  const removeFromCollection = collectionId => e => {
    removePostFromCollection(postId, collectionId)
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={true}
      keyboard={true}
      scrollable={true}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>Your Collections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Save into or remove from collections that you can share or view as slideshows.</p>
        {availableCollections &&
          <>
            {availableCollections.length > 0 ?
              <>
                <h4>Add to a collection</h4>
                <ListGroup>
                  {availableCollections.map(collection => (
                    <ListGroup.Item className={"px-0"} key={collection.id}>
                      <Row className={"p-0 m-0"}>
                        <Col xs={3} lg={2}>
                          {collection.posts && collection.posts[0] && <CollectionThumb collection={collection} index={0} />}
                        </Col>
                        <Col>
                          <span style={{fontWeight: "bold"}}>{collection.title}</span><br />
                          <span className={"collection-stats"}>{collection.posts.length} posts</span>
                        </Col>
                        <Col className={"text-right"}>
                          <Button variant={"secondary"} size={"md"} onClick={addToCollection(collection.id)}><BsFillSave2Fill /> Add</Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
              :
              <Alert variant="info">
                You do not have any available collections, you can create a new collection to save this post into.
              </Alert>
            }
          </>
        }
        <AddCollection postId={postId} user={user} setShow={setShow} heading={"Create new collection with this post"} />
        {existingCollections &&
          <>
            {existingCollections.length > 0 ?
              <>
                <h4>Remove from collections</h4>
                <ListGroup>
                  {existingCollections.map(collection => (
                    <ListGroup.Item className={"px-0"} key={collection.id}>
                      <Row className={"p-0 m-0"}>
                        <Col xs={3} lg={2}>
                          {collection.posts && collection.posts[0] && <CollectionThumb collection={collection} index={0} />}
                        </Col>
                        <Col>
                          <span style={{fontWeight: "bold"}}>{collection.title}</span><br />
                          <span className={"collection-stats"}>{collection.posts.length} posts</span>
                        </Col>
                        <Col className={"text-right"}>
                          <Button variant={"danger"} size={"md"} onClick={removeFromCollection(collection.id)}><BsFillBagXFill /> Remove</Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
              :
              <Alert variant="warning">
                This post is not in any collections of yours.
              </Alert>
            }
          </>
        }

      </Modal.Body>
    </Modal>
  )
}

export default CollectionsModal