import {Button, Col, Modal, Row} from "react-bootstrap";
import React, {useState} from "react";
import CollectionThumb from "./CollectionThumb";
import {Link} from "../util/router";
import {pluralise} from "../util/strings";
import {BsPlayCircle, BsTrash} from "react-icons/bs";
import Slideshow from "./Slideshow";
import {usePostsInList} from "../util/db";

const CollectionListItem = props => {
  const { collection, onClick, isOwner } = props
    console.log('collection posts', collection.posts)
    const {data: posts, status} = usePostsInList(collection.posts, collection.id)
  const [ show, setShow ] = useState(false)
    const handleClose = e => {
        setShow(false)
    }
  return (
    <Row className={"p-0 m-0"}>
      <Col xs={3} lg={2} className={"p-0 m-0"}>
        {collection.posts && collection.posts.length > 0 &&
          <CollectionThumb collection={collection} index={0}/>}
        {collection.posts && props.collection.posts.length > 1 &&
          <CollectionThumb collection={collection} index={1}/>}
      </Col>
      <Col className={"p-0 m-0"}>
        <Link to={"/collection/" + collection.id}
              className={"collection-title"}>
          {collection.title}
        </Link>
        <br/>
        <span
          className={"collection-stats"}>
          {collection.posts.length} {pluralise(collection.posts.length, "post", "posts")}
        </span>
          {status !== "loading" && posts &&
              <Modal
                  show={show}
                  size={"xl"}
                  onHide={handleClose}
                  backdrop={true}
                  keyboard={true}
                  scrollable={false}
              >
                  <Modal.Header closeButton={true}>
                      <Modal.Title>{collection.title}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{position: "relative", height: "80vh"}}>
                      {posts &&
                          <Slideshow posts={posts}/>}
                  </Modal.Body>
              </Modal>
          }

      </Col>
      <Col className={"p-0 m-0 text-right"}>
          <Button className="bg-transparent btn-outline-transparent" onClick={() => setShow(true)}><BsPlayCircle color={"white"} size={"40px"}/></Button>
          {isOwner && <Button variant={"danger"} onClick={onClick}><BsTrash/></Button>}
      </Col>
    </Row>
  )
}

export default CollectionListItem