import {Col, Row} from "react-bootstrap";
import "./Comments.scss"
import {BsPersonX} from "react-icons/bs";

const BlockedComment = (props) => {
  const { odd } = props
  return (
    <Row>
      <Col style={{textAlign: "center"}}>
        {!odd && <><BsPersonX /> Muted artist</>}
      </Col>
      <Col xs={8}>
        <div  className={odd ? "bubble blocked-comment odd" : "bubble blocked-comment even"}>Comment from a muted artist</div>
      </Col>
      <Col style={{textAlign: "center"}}>
        {odd && <><BsPersonX /> Muted artist</>}
      </Col>
    </Row>
  )
}

export default BlockedComment;