import {Button, Col, Row} from "react-bootstrap";
import {BsPlayCircle} from "react-icons/bs";
import React from "react";
import Owner from "./Owner";
import "./PostListHeader.scss"

function PostListHeader(props) {
  const { withSlideShow, owner, title, onClick } = props
  return (
      <>
        {withSlideShow ?
                <Row>
                  <Col>
                    <h2>{title} {owner && <small className="text-muted">by <Owner owner={owner} size={"24px"} /></small>}</h2>
                  </Col>
                  <Col className={"text-right"}>
                    <Button className="bg-transparent btn-outline-transparent" onClick={onClick}><BsPlayCircle color={"white"} size={"40px"}/></Button>
                  </Col>
                </Row>
            :  <h2>{title} {owner && <small className="text-muted">by <Owner owner={owner} size={"24px"} /></small>}</h2>
        }
      </>

  )
}

export default PostListHeader
