import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  arrayUnion, arrayRemove,
  documentId,
  startAt,
    limit
} from "firebase/firestore";
import { firebaseApp } from "./firebase";
import {makeUsername} from "./users";

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "users", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

export function useUsers(id, users) {
  return useQuery(
      ["subs", { id }],
      createQuery(() =>
        query(
          collection(db, "users"),
          where(documentId(), "in", users)
        )
      ),
      { enabled: !!id && users.length > 0 }
  )
}

// Fetch user data once (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return getDoc(doc(db, "users", uid)).then(format);
}

// Create a new user
export function createUser(uid, data) {
  if (!data.username) {
    data.username = makeUsername(data.email)
  }
  return setDoc(doc(db, "users", uid), data, { merge: true });
}

// Update an existing user
export function updateUser(uid, data) {
  return updateDoc(doc(db, "users", uid), data);
}

export function subscribeToUser(id, userId) {
  return updateDoc(doc(db, "users", id), {
    subscribedTo: arrayUnion(userId)
  })
}

export function unsubscribeToUser(id, userId) {
  return updateDoc(doc(db, "users", id), {
    subscribedTo: arrayRemove(userId)
  })
}

export function blockUser(authUser, id) {
  return updateDoc(doc(db, "users", authUser), {
    blocked: arrayUnion(id),
    subscribedTo: arrayRemove(id)
  })
}

export function unblockUser(authUser, id) {
  return updateDoc(doc(db, "users", authUser), {
    blocked: arrayRemove(id)
  })
}

/**** POSTS ****/

export function useCategory(id) {
  return useQuery(
      ["category", id],
      createQuery(() =>
        query(
            doc(db, "categories", id)
        )),
      { enabled: !!id }
  )
}

export function useCategories() {
  return useQuery(
      ["categories"],
      createQuery(() =>
          query(
              collection(db, "categories"),
              orderBy("name", "asc")
          )
      ),
      { enabled: true }
  )
}

export function usePost(id) {
  return useQuery(
      ["post", { id }],
      createQuery(() => doc(db, "posts", id)),
      { enabled: !!id }
  )
}

export function useComments(id) {
  return useQuery(
      ["comments", { id }],
      createQuery(() =>
            query(
                collection(db, "posts", id, "comments"),
                orderBy("createdAt", "asc")
            )
      ),
          { enabled: !!id }
  )
}

export function usePostOnce(id) {
  return useQuery(
      ["post", { id }],
      () => getDoc(doc(db, "posts", id)).then(format),
      { enabled: !!id }
  );
}

export function usePostsByOwner(owner) {
  return useQuery(
      ["posts", { owner }],
      createQuery(() =>
          query(
              collection(db, "posts"),
              where("owner", "==", owner),
              orderBy("createdAt", "desc")
          )
      ),
      { enabled: !!owner }
  );
}

export function usePosts() {
  return useQuery(
    ["allPosts"],
    createQuery(() =>
      query(
        collection(db, "posts"),
        orderBy("createdAt", "desc"),
        limit(100)
      )
    ),
    { enabled: true }
  );
}

export async function cascadeDeletePost(postId) {
  const q = query(collection(db, "collections"), where("posts", "array-contains", postId));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((d) => {
    return updateDoc(doc(db, "collections", d.id), {
      posts: arrayRemove(postId)
    })
  })
  return new Promise((resolve, reject) => resolve(deleteDoc(doc(db, "posts", postId))))
}

export function usePostsInList(list, collectionId) {
  return useQuery(
    ["collectionPosts", collectionId],
    createQuery(() =>
      query(
        collection(db, "posts"),
        where(documentId(), "in", list)
      )
    ),
    { enabled: list.length > 0 }
  );
}

export function usePostsInPage(start, limit) {
  return useQuery(
    ["postsInPage", start],
    createQuery(() =>
      query(
        collection(db, "posts"),
        orderBy("createdAt", "desc"),
        startAt(start),
        limit(limit)
      )
    ),
    { enabled: start > 0 }
  )
}

export function useFeed(authUser) {
  return useQuery(
      ["feedPosts", authUser],
      createQuery(() =>
        query(
          collection(db, "posts"),
          orderBy("createdAt", "desc")
      )
  ),
      { enabled: true }
);
}

export function createPost(data) {
  return addDoc(collection(db, "posts"), {
    ...data,
    createdAt: serverTimestamp(),
    likedBy: []
  });
}


export function createComment(postId, data) {
  return addDoc(collection(db, "posts", postId, "comments"), {
    ...data,
    createdAt: serverTimestamp()
  })
}

export function addLike(id, likedBy) {
  return updateDoc(doc(db, "posts", id), {
    likedBy: arrayUnion(likedBy)
  })
}

export function removeLike(id, likedBy) {
  return updateDoc(doc(db, "posts", id), {
    likedBy: arrayRemove(likedBy)
  })
}

export function updatePost(id, data) {
  return updateDoc(doc(db, "posts", id), data);
}

export function deletePost(id) {
  return deleteDoc(doc(db, "posts", id));
}


export function addPostToCollection(postId, collectionId) {
  return updateDoc(doc(db, "collections", collectionId), {
    posts: arrayUnion(postId)
  })
}

export function removePostFromCollection(postId, collectionId) {
  return updateDoc(doc(db, "collections", collectionId), {
    posts: arrayRemove(postId)
  })
}

export function useCollection(id) {
  return useQuery(
    ["collection", id],
    createQuery(() =>
    query(
      doc(db, "collections", id)
    )),
    {enabled: !!id}
  )
}

export function deleteCollection(id) {
  return deleteDoc(doc(db, "collections", id))
}

export function useCollections(user) {
  return useQuery(
    ["collection", { user }],
    createQuery(() =>
      query(
        collection(db, "collections"),
        where("owner", "==", user)
      )
    ),
    { enabled: !!user }
  )
}


export function createCollection(data) {
  return addDoc(collection(db, "collections"), {
    ...data,
    createdAt: serverTimestamp()
  })
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Subscribe to item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    createQuery(() => doc(db, "items", id)),
    { enabled: !!id }
  );
}

// Fetch item data once
export function useItemOnce(id) {
  return useQuery(
    ["item", { id }],
    // When fetching once there is no need to use `createQuery` to setup a subscription
    // Just fetch normally using `getDoc` so that we return a promise
    () => getDoc(doc(db, "items", id)).then(format),
    { enabled: !!id }
  );
}

// Subscribe to all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    createQuery(() =>
      query(
        collection(db, "items"),
        where("owner", "==", owner),
        orderBy("createdAt", "desc")
      )
    ),
    { enabled: !!owner }
  );
}

// Create a new item
export function createItem(data) {
  return addDoc(collection(db, "items"), {
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Update an item
export function updateItem(id, data) {
  return updateDoc(doc(db, "items", id), data);
}

// Delete an item
export function deleteItem(id) {
  return deleteDoc(doc(db, "items", id));
}

/**** HELPERS ****/

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
