import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "../util/auth";
import EditPost from "../components/EditPost";
import {useCategories, usePost} from "../util/db";
import {useRouter} from "../util/router";

function EditPostPage(props) {
  const router = useRouter()
  const { data: post } = usePost(router.query.postId)
  const { data: categories } = useCategories()
  return (
    <>
      <Meta title="Edit post" />
      {post && categories && <EditPost post={post} categories={categories} />}
    </>
  );
}

export default requireAuth(EditPostPage)
