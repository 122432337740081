import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {createComment} from "../util/db";

const AddComment = (props) => {
  const { postId, user } = props
  const [ comment, setComment ] = useState('')
  const handleChange = fn => e => {
    fn(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (user && comment) {
      createComment(postId, {
        comment,
        owner: user.id,
        isDeleted: false
      }).then(() => setComment(''))
    }
  }
  return (
      <Form className="mt-3 mb-3" onSubmit={handleSubmit}>
        <Form.Group controlId="title">
          <Form.Label>Add a comment</Form.Label>
          <Form.Control className="input-comment" type="text" onChange={handleChange(setComment)} value={comment} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Post comment
        </Button>
      </Form>
  )
}

export default AddComment
