import {usePosts} from "../util/db";
import Posts from "./Posts";
import React from "react";

const PublicPosts = props => {
    const { categories } = props
    const { data } = usePosts()
    return (
        <>
            {data && categories && <Posts title="Public feed" categories={categories} posts={data} />}
        </>
    )
}

export default PublicPosts