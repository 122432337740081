import {usePosts} from "../util/db";
import Posts from "./Posts";
import React from "react";

const FeedPosts = props => {
    const { authUser, categories } = props
    const { data } = usePosts()
    return (
        <>
            {data && categories && categories.length > 0 && <Posts title="Your feed" categories={categories} posts={data} authUser={authUser} />}
        </>
    )
}

export default FeedPosts