import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "../util/auth";
import Collections from "../components/Collections";

function MyCollections() {
  const auth = useAuth();
  return (
    <>
      <Meta title="Your collections" />
      <Collections title={"Your collections"} user={auth.user.id} viewer={auth.user.id} />
    </>
  );
}

export default requireAuth(MyCollections);
