import Avatar from "./Avatar";
import React from "react";
import "./PostOwner.scss";

const UserMenu = props => {
    const { user } = props
    return (
        <>
            {user.photoURL ?
                <Avatar src={user.photoURL} alt={user.name} size={"32px"} />
                :
                <span className="avatar-placeholder">{user.name[0]}</span>
            }
        </>
    )
}

export default UserMenu