import Avatar from "./Avatar";
import React from "react";
import {useUser} from "../util/db";
import "./PostOwner.scss";
import {Link} from "../util/router";

const Owner = props => {
    const { owner, size, hideName, ctx } = props
    const { data } = useUser(owner)
    return data ? (
        <div className={ctx === "comments" ? "comment-user" : "user-detail"}>
          <div>
            <Link to={"/profile/" + owner } style={{color: "white"}}>
              {data.photoURL ?
                <Avatar src={data.photoURL} alt={data.name} size={size || "32px"} />
                :
                <span className="avatar-placeholder">{data.name[0]}</span>
              }
            </Link>
            </div>
          {!hideName &&
            <div className="details">
              <Link to={"/profile/" + owner } style={{color: "white"}}>
                <span className={ctx === "comments" ? "name-display truncate" : "user-detail"}>{data.username || data.name}</span>
              </Link>
            </div>
          }
        </div>
    ) : (<div />)
}

export default Owner