import React from "react"
import Button from 'react-bootstrap/Button'
import './FilterButton.scss'

const FilterButton = props => {
  const { active, filter, label, filterPosts } = props
  return (
    <Button
      key={`content-filter-${filter}`}
      className={active ? "mr-2 active-filter" : "mr-2"}
      variant="dark"
      size="sm"
      onClick={filterPosts(filter)}
    >
      {label}
    </Button>
  )
}

export default FilterButton
