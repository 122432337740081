import Avatar from "./Avatar";
import React from "react";
import {resizeImg} from "../util/images";
import {usePostsByOwner} from "../util/db";
import Loading from "./Loading";
import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import Posts from "./Posts";
import "./Profile.scss"
import {useAuth} from "../util/auth";
import {Link} from "../util/router";
import UserRelations from "./UserRelations";
import Collections from "./Collections";

const Profile = props => {
    const { profile, categories } = props
    const auth = useAuth()
    const { data: posts, status: postsStatus } = usePostsByOwner(profile.id)
    return (
      <>
          <Container>
              <Card text={"light"} className="m-2 profile-card">
                  <Card.Header>
                      <Row>
                          <Col sm={3}>
                              {profile.photoURL ?
                                <Avatar src={resizeImg(profile.photoURL, 128)} alt={profile.name} size="128px" className="ml-0" />
                                :
                                <span className="avatar-placeholder">{profile.name[0]}</span>
                              }
                          </Col>
                          <Col sm={6}>
                              <h1 className="font-weight-bold">
                                  {profile.name}
                              </h1>
                              <div className={"user-name mt-1 mb-1"}>@{profile.username.toLowerCase() || profile.name.toLowerCase()}</div>
                              {profile.bio && <p className="bio">{profile.bio}</p>}
                          </Col>
                          <Col style={{textAlign: "right"}}>
                              {auth.user ?
                                <UserRelations authUser={auth.user} otherUser={profile.id} />
                                :
                                <Link to="/auth/signin">
                                    Sign in to subscribe
                                </Link>
                              }
                          </Col>
                      </Row>
                  </Card.Header>
              </Card>
              <Tabs variant="pills" className={"mt-4 card-header profile-tabs"} defaultActiveKey="posts" id="profile-tabs">
                  <Tab className={"pt-4"} eventKey="posts" title="Posts">
                      {postsStatus === "loading" && <Loading />}
                      {postsStatus !== "loading" && posts &&
                          <Posts
                              withSlideShow={true}
                              title={"Posts"}
                              categories={categories}
                              posts={posts}
                          />}
                  </Tab>
                  <Tab className={"pt-4"} eventKey="collections" title="Collections">
                      <Collections title={"Collections"} user={profile.id} viewer={auth?.user?.id} />
                  </Tab>
              </Tabs>
          </Container>
      </>
    )
}

export default Profile
