import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Uploader from './Uploader'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {createPost, useCategories} from '../util/db'
import Image from 'react-bootstrap/Image'
import { useRouter } from '../util/router'
import {requireAuth, useAuth} from "../util/auth";
import {resizeImg} from "../util/images";

const AddImage = () => {
  const auth = useAuth();
  const router = useRouter()
  const { data: categories } = useCategories()
  const [ title, setTitle ] = useState('')
  const [ category, setCategory ] = useState('')
  const [ imageUrl, setImageUrl ] = useState('')

  const handleChange = fn => e => {
    fn(e.target.value)
  }

  const processUpload = url => Promise.resolve(setImageUrl(url))

  const handleSubmit = e => {
    e.preventDefault()
    if (auth.user && imageUrl) {
      createPost({
        title: title.toString().trimEnd().trimStart(),
        image: resizeImg(imageUrl, 1200),
        category: category,
        owner: auth.user.uid
      }).then(docRef => {
        router.push('/')
      })
    }
  }

  return (
      <Container className="p-3">
        <h1 className={"my-5"}>Make a new post</h1>
        <Uploader processUpload={processUpload} />
        {imageUrl && <Image src={imageUrl} fluid />}
        <Form className="mt-3" onSubmit={handleSubmit}>
          <Form.Group controlId="title">
            <Form.Label>Caption</Form.Label>
            <Form.Control type="text" onChange={handleChange(setTitle)} value={title} />
          </Form.Group>
          {categories &&
              <Form.Group controlId="category">
                <Form.Label>What type of art is this?</Form.Label>
                <Form.Control as="select" onChange={handleChange(setCategory)} value={category}>
                  <option>Choose a category</option>
                  {categories.length > 0 && categories.map(c => (
                      <option key={c.id} value={c.id}>{c.name}</option>
                  ))
                  }
                </Form.Control>
              </Form.Group>
          }
          <Button variant="primary" type="submit">
            Post
          </Button>
        </Form>
      </Container>
  )
}

export default requireAuth(AddImage)
