import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FormField from "./FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import Uploader from './Uploader'
import Avatar from "./Avatar";
import {resizeImg} from "../util/images";


function SettingsGeneral(props) {
    const auth = useAuth();
    const [pending, setPending] = useState(false);
    const [imageUrl, setImageUrl ] = useState(auth.user.photoURL)
    const processUpload = url => auth
        .updateProfile({photoURL: url})
        .then(() => {
            setImageUrl(url)
        })
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = (data) => {
        // Show pending indicator
        setPending(true);

        return auth
            .updateProfile(data)
            .then(() => {
                // Set success status
                props.onStatus({
                    type: "success",
                    message: "Your profile has been updated",
                });
            })
            .catch((error) => {
                if (error.code === "auth/requires-recent-login") {
                    props.onStatus({
                        type: "requires-recent-login",
                        // Resubmit after reauth flow
                        callback: () => onSubmit(data),
                    });
                } else {
                    // Set error status
                    props.onStatus({
                        type: "error",
                        message: error.message,
                    });
                }
            })
            .finally(() => {
                // Hide pending indicator
                setPending(false);
            });
    };

    return (
        <>
            <div className="mb-3">
                {imageUrl &&<Avatar src={resizeImg(imageUrl, 128)} format="circle" size="128px" />}
                <div className="mt-3">
                    <Uploader processUpload={processUpload} />
                </div>
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="formName">
                    <FormField
                        name="name"
                        type="text"
                        label="Name"
                        defaultValue={auth.user.name}
                        placeholder="Name"
                        error={errors.name}
                        inputRef={register({
                            required: "Please enter your artist name",
                        })}
                    />
                </Form.Group>
                <Form.Group controlId="formUsername">
                    <FormField
                        name="username"
                        type="text"
                        label="Username (a nickname to use here)"
                        defaultValue={auth.user.username}
                        placeholder="User name"
                        error={errors.username}
                        inputRef={register({
                            required: "Please enter your username",
                            validate: (value) => {
                                if (/^[0-9a-zA-Z_]{3,16}$/.test(value)) {
                                    return true;
                                } else {
                                    return "Usernames can only be between 3 and 16 letters, numbers or underscores, no spaces or dashes can be used. ";
                                }
                            },
                        })}
                    />
                </Form.Group>
                <Form.Group controlId="formBio">
                    <FormField
                        name="bio"
                        type="textarea"
                        label="Bio"
                        defaultValue={auth.user.bio}
                        placeholder=""
                        error={errors.bio}
                        inputRef={register()}
                        style={{minHeight: "7em"}}
                    />
                </Form.Group>
                <Form.Group controlId="formEmail">
                    <FormField
                        name="email"
                        type="email"
                        label="Email Address"
                        defaultValue={auth.user.email}
                        placeholder="Email"
                        error={errors.email}
                        inputRef={register({
                            required: "Please enter your email",
                        })}
                    />
                </Form.Group>
                <Button type="submit" disabled={pending}>
                    <span>Save</span>

                    {pending && (
                        <Spinner
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden={true}
                            className="ml-2"
                        >
                            <span className="sr-only">Sending...</span>
                        </Spinner>
                    )}
                </Button>
            </Form>
        </>
    );
}

export default SettingsGeneral;
