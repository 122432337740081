import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import {Alert, Container} from "react-bootstrap";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bg="primary"
        textColor="white"
        size="lg"
        bgImage="https://res.cloudinary.com/nubz/image/upload/w_1200/v1664286627/publishr/mhacd6fynldn43kjqi85.jpg"
        bgImageOpacity={0.2}
        title="Sharing art only"
        subtitle="Sharing art on other platforms can be hit and miss, there's a lot of other stuff competing for attention.
        Grokit.art is dedicated to artists."
      />
        <Container>
            <Alert variant="info">
                The service is currently in private beta meaning only invited users are here for the purposes of testing the
                service. This means some production features are yet to be included such as identity verification, you are trusted
                to not abuse the service while being kind enough to help test it! Thank you and happy grokking!
            </Alert>
        </Container>
    </>
  );
}

export default AboutPage;
