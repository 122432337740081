import React from "react";
import {BsThreeDotsVertical} from "react-icons/bs";

const ThreeDotToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#postOptions"
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <BsThreeDotsVertical color={"#ccc"} size="28px" />
        {children}

    </a>
))

export default ThreeDotToggle