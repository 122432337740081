import React from "react";

function Avatar(props) {
  const { src, size, ...otherProps } = props;

  return (
    <div
      {...otherProps}
      style={{
        textAlign: "center",
        borderRadius: "50%",
        width: size,
        height: size,
        display: "inline-block",
        margin: "3px 5px 3px 3px",
        backgroundImage: "url(" + src + ")",
        backgroundPosition: "center",
        backgroundSize: "cover"
      }}
    />
  );
}

export default Avatar;
