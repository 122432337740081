import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "../util/auth";
import {Container} from "react-bootstrap";
import UserList from "../components/UserList";

function MyArtists(props) {
    const auth = useAuth();
    return (
        <>
            <Meta title="My artists" />
            <Container>
                <h1 className="mt-2">My artists</h1>
                {auth.user && <UserList
                    authUser={auth.user}
                    userList={auth.user.subscribedTo}
                    emptyAlert="You have not subscribed to any artists, when you subscribe to an artist they will be saved here and you may receive notifications about their posts."
                />}
            </Container>
        </>
    );
}

export default requireAuth(MyArtists);