import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "../util/auth";
import {Container} from "react-bootstrap";
import UserList from "../components/UserList";

function BlockedArtists(props) {
    const auth = useAuth();
    return (
        <>
            <Meta title="Muted artists" />
            <Container>
                <h2 className="mt-2">Muted artists</h2>
                <p>You are not seeing posts or comments from your muted artists.</p>
                {auth.user && <UserList
                    authUser={auth.user}
                    userList={auth.user.blocked}
                    emptyAlert="You have not muted any artists, when you silently mute an artist you will no longer see their posts or comments."
                />}
            </Container>
        </>
    );
}

export default requireAuth(BlockedArtists);