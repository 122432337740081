import React from "react";
import Meta from "./../components/Meta";
import { useRouter } from "./../util/router";
import {useCategories, useUser} from "../util/db";
import Loading from "../components/Loading";
import Profile from "../components/Profile";

function ProfilePage() {
  const router = useRouter()
  const { data: categories } = useCategories()
  const { data: profile, status: profileStatus } = useUser(router.query.user)
  return (
    <>
      <Meta title="Settings" />
      {profileStatus === "loading" && <Loading />}
      {profileStatus !== "loading" && categories && profile && (<Profile categories={categories} profile={profile} />)}
    </>
  )
}

export default ProfilePage
