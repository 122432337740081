import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import AddImagePage from "./add-image";
import MyPostsPage from "./my-posts";
import MyCollections from "./my-collections"
import Collection from "./collection"
import PostPage from "./post";
import EditPostPage from "./edit-post";
import AuthPage from "./auth";
import ProfilePage from "./profile"
import SettingsPage from "./settings";
import LegalPage from "./legal";
import MyArtistsPage from "./my-artists"
import BlockedArtistsPage from "./blocked-artists"
import { Switch, Route, Router } from "./../util/router";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import { AuthProvider } from "../util/auth";
import { QueryClientProvider } from "../util/db";

function App(props) {
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Router>
          <>
            <NavbarCustom
              bg="dark"
              variant="dark"
              expand="md"
              logo=""
            />

            <Switch>
              <Route exact path="/" component={IndexPage} />

              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/faq" component={FaqPage} />

              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/pricing" component={PricingPage} />

              <Route exact path="/dashboard" component={AddImagePage} />
              <Route exact path="/my-posts" component={MyPostsPage} />
              <Route exact path="/my-collections" component={MyCollections} />
              <Route exact path="/collection/:collectionId" component={Collection} />
              <Route exact path="/my-artists" component={MyArtistsPage} />
              <Route exact path="/blocked-artists" component={BlockedArtistsPage} />

              <Route exact path="/posts/:postId" component={PostPage} />
              <Route exact path="/edit-post/:postId" component={EditPostPage} />
              <Route exact path="/posts/:postId/:element" component={PostPage} />
              <Route exact path="/profile/:user" component={ProfilePage} />

              <Route exact path="/auth/:type" component={AuthPage} />

              <Route exact path="/settings/:section" component={SettingsPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route
                exact
                path="/firebase-action"
                component={FirebaseActionPage}
              />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bg="dark"
              textColor="light"
              size="sm"
              bgImage=""
              bgImageOpacity={1}
              copyright={`© ${new Date().getFullYear()} Alistair Macdonald`}
              logo=""
            />
          </>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
