import React from "react";
import "./Slideshow.scss"

const delay = 2500;

function Slideshow(props) {
  const { posts } = props
    console.log('posts', posts)
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === posts.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, posts.length]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {posts?.length > 1 && posts.map((post, index) => (
          <div
            className="slide"
            key={index}
            style={{ backgroundImage: "url(" + post.image + ")" }}
          ></div>
        ))}
      </div>

      <div className="slideshowDots">
        {posts?.length > 1 && posts.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Slideshow
