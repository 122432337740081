import Card from "react-bootstrap/Card";
import React from "react";
import {Link} from "../util/router";
import PostActionBar from "./PostActionBar";
import "./Post.scss";
import {resizeImg} from "../util/images";
import PostHeader from "./PostHeader";

const PostFeedItem = props => {
    const { post } = props
    return (
        <Card text="dark" className="text-decoration-none mb-3">
            <Card.Header className="tight-header">
                <PostHeader post={post} />
            </Card.Header>
            <Link to={"/posts/" + post.id}>
                <Card.Img src={resizeImg(post.image, 1200)} alt={post.title} variant="top"/>
            </Link>
            <Card.Footer className="tight-header">
                {post.title && <h2 style={{paddingLeft: "0.5em", color: "#ccc", marginTop: "0.5em", marginBottom: "1em", fontSize: "1.5em"}}>“{post.title}”</h2>}
                <PostActionBar post={post} />
            </Card.Footer>
        </Card>
    )
}

export default PostFeedItem