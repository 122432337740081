import React, {useContext} from "react"
import FilterButton from './FilterButton'
import './Filters.scss'
import {BsFillFunnelFill} from "react-icons/bs";
import {Accordion, Button, AccordionContext, useAccordionToggle} from "react-bootstrap";

function AccordionToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button
      className={isCurrentEventKey ? 'active-toggle' : ''}
      onClick={decoratedOnClick}
      variant={"dark"}
    >
      <BsFillFunnelFill />
      {children}
    </Button>
  );
}

const Filters = props => {
  const { activeFilter, includedCategories, filterPosts } = props
  return (
    <Accordion className={"filters"}>
      <AccordionToggle eventKey="0">Filter posts</AccordionToggle>
      <Accordion.Collapse eventKey="0" className={"mb-2 pb-2"}>
        <>
          <FilterButton
            active={activeFilter === "all"}
            filter='all'
            label='All'
            filterPosts={filterPosts} />

          {includedCategories.length > 0 && includedCategories.map(c => (
            <FilterButton
              key={"filter-button-" + c.id}
              active={activeFilter === c.id}
              filter={c.id}
              label={c.name}
              filterPosts={filterPosts} />
          ))}
        </>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default Filters
