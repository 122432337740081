import React, {useEffect, useState} from "react";
import {useCategories} from "../util/db";
import {Alert, Container, Modal} from "react-bootstrap";
import PostsList from "./PostsList";
import PostListHeader from "./PostListHeader";
import SlideshowCollector from "./SlideshowCollector";

const chunkArray = (list, chunk) => {
  const result = []

  for (let i = 0; i < list.length; i += chunk) {
    result.push(list.slice(i, i + chunk))
  }

  return result
}

const CollectionPosts = props => {
  const { collection } = props
  const { data: categories } = useCategories()
  const [ pages, setPages ] = useState([])
  useEffect(() => {
    const newPages = collection.posts && collection.posts.length > 10 ?
      chunkArray([...collection.posts], 10) : [collection.posts]
    setPages(newPages)
  }, [collection.posts])

  const [ show, setShow ] = useState(false)
  const handleClose = e => {
    setShow(false)
  }
  return (
    <>
      {categories &&
        <>
            {pages.length <= 0  ?
              <Container>
                <h2 className={"my-3"}>{collection.title}</h2>
                <Alert variant="info">This is currently an empty collection.</Alert>
              </Container>
              :
              <>
                <Container>
                  <PostListHeader owner={collection.owner} title={collection.title} withSlideShow={true} onClick={() => setShow(true)} />
                </Container>
                {pages.map((page, index) => (
                  <PostsList key={"page-in-list-" + index} pageIndex={index} collectionId={collection.id} title={collection.title} postIds={page} categories={categories} />
                 ))}
              </>
            }
        </>
      }
      <Modal
        show={show}
        size={"xl"}
        onHide={handleClose}
        backdrop={true}
        keyboard={true}
        scrollable={false}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>{collection.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{position: "relative", height: "80vh"}}>
          <SlideshowCollector collection={collection} pages={pages}/>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CollectionPosts