import React from "react";
import Meta from "./../components/Meta";
import {useCollection} from "../util/db";
import Loading from "../components/Loading";
import CollectionPosts from "../components/CollectionPosts";
import {useRouter} from "../util/router";
import {Container} from "react-bootstrap";

function Collection() {
  const router = useRouter();
  const { data: collection, status } = useCollection(router.query.collectionId)
  return (
    <>
      <Meta title={collection ? collection.title : "Collection"} />
      {status === "loading" && <Container className="p-5"><Loading /></Container>}
      {status !== "loading" && collection && <CollectionPosts collection={collection}/>}
    </>
  );
}

export default Collection;
