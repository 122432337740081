import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. It’s our policy to respect your privacy
        and comply with any applicable law and regulation regarding the personal
        information we collect about you through this website, {props.domain},
        and through any associated services and third-party platforms we may
        use.
      </p>
      <p>This policy was last updated on 30 September 2022.</p>
      <h2>Information we collect</h2>
      <p>
        Personal information is any information about you which can be used to
        identify you. Any information we collect may be automatically collected
        (i.e. information automatically sent to us by your devices when you
        access our services) or voluntarily provided (i.e. information you
        knowingly and actively provide us).
      </p>
        <p>
            We will never sell your personal information to anyone, ever. We commit to
            keeping your personal information safe and secure and to be used solely for
            the purposes of participating in the sharing and engaging with art works posted
            here on grokit.art.
        </p>
      <h3>Automatically collected information</h3>
      <p>
        Our servers automatically log standard data provided by your web browser
        when you visit our website. This data may include your device’s IP
        address, device type, operating system, browser type and version, the
        pages you visit, the time and date of your visit, time spent on each
        page, and other details about your visit or device.
      </p>
    </LongContent>
  );
}

export default LegalPrivacy;
