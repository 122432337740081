import React from "react";
import Owner from "./Owner";
import ThreeDotMenu from "./ThreeDotMenu";
import {Col, Row} from "react-bootstrap";
import {useAuth} from "../util/auth";
import PostAdmin from "./PostAdmin";

const PostHeader = props => {
    const { post } = props
    const auth = useAuth();
    return (
        <>
            <Row className="p-0 m-0">
                <Col className="pl-0">
                    <Owner owner={post.owner}/>
                </Col>
                {auth.user &&
                    <Col className="pr-2 pt-1" style={{textAlign: "right"}}>
                        {auth.user.id === post.owner ?
                            <PostAdmin post={post} authUser={auth.user.id} />
                            :
                            <ThreeDotMenu post={post} authUser={auth.user.id}/>
                        }
                    </Col>
                }
            </Row>
        </>
    )
}

export default PostHeader
