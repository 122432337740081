import React, {useState} from "react";
import {Button, Dropdown} from "react-bootstrap";
import {cascadeDeletePost} from "../util/db";
import PostAdminToggle from "./PostAdminToggle";
import Modal from "react-bootstrap/Modal";
import {useRouter} from "../util/router";
import CollectionsModal from "./CollectionsModal";

const PostAdmin = props => {
    const { post } = props
    const router = useRouter()
    const [ showCollections, setShowCollections ] = useState(false)
    const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)
    const handleClose = e => {
        setShowConfirmDelete(false)
    }
    const handleCloseCollections = e => {
        setShowCollections(false)
    }
    const deleteThisPost = () => {
        cascadeDeletePost(post.id).then(() => router.push("/my-posts"))
    }
    return (
        <>

        <Dropdown>
            <Dropdown.Toggle as={PostAdminToggle} />
            <Dropdown.Menu variant="dark">
                <Dropdown.Header>
                    Posted: {post.createdAt.toDate().toDateString()}
                </Dropdown.Header>
                <Dropdown.Item href={"/edit-post/" + post.id}>Edit this post</Dropdown.Item>
                <Dropdown.Item onClick={(e) => setShowConfirmDelete(true)}>Delete this post</Dropdown.Item>
                <Dropdown.Item onClick={() => setShowCollections(true)}>Add to or remove from a collection</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <CollectionsModal show={showCollections} setShow={setShowCollections} user={post.owner} handleClose={handleCloseCollections} postId={post.id} />
        <Modal
            show={showConfirmDelete}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>Delete “{post.title}”?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                <p>You cannot undo the deletion of a post, all likes, comments and reposts will also be deleted.</p>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>{' '}
                <Button variant="success" onClick={deleteThisPost}>
                    Delete post
                </Button>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default PostAdmin