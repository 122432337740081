import {useUsers} from "../util/db";
import {Alert, Col, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import User from "./User";
import UserRelations from "./UserRelations";
import React from "react";
const UserList = props => {
    const { authUser, userList, emptyAlert } = props
    const { data: users } = useUsers(authUser.id, userList || [])
    return (
        <>
            {users && users.length > 0 ?
                <ListGroup>
                    {users.map(user => (
                        <ListGroupItem variant="dark" className="pl-0" key={user.id}>
                            <Row className="p-0 m-0">
                                <Col className="p-0">
                                    <User user={user} />
                                </Col>
                                <Col className="p-0" style={{textAlign: "right"}}>
                                    <UserRelations authUser={authUser} otherUser={user.id} />
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))}
                </ListGroup> :
                <Alert variant="info">
                    {emptyAlert}
                </Alert>
            }
        </>
    )
}

export default UserList