import React from "react";
import {Dropdown} from "react-bootstrap";
import {blockUser} from "../util/db";
import ThreeDotToggle from "./ThreeDotToggle";

const ThreeDotMenu = props => {
  const { post, authUser } = props
  const blockThisUser = user => event => {
    blockUser(authUser, user)
  }
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={ThreeDotToggle} />
        <Dropdown.Menu variant="dark">
          <Dropdown.Header>Posted: {post.createdAt.toDate().toDateString()}</Dropdown.Header>
          <Dropdown.Item onClick={blockThisUser(post.owner)}>Mute this artist</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>

  )
}

export default ThreeDotMenu