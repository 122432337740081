import React from "react";
import {BsFillGearFill} from "react-icons/bs";

const PostAdminToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#postOptions"
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <BsFillGearFill color={"#988698"} size="28px" />
    {children}

  </a>
))

export default PostAdminToggle