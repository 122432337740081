import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth } from "../util/auth";
import {BsHeartFill} from "react-icons/bs";
import {Container, Navbar, Nav, NavDropdown} from "react-bootstrap";
import UserMenu from "./UserMenu";
function NavbarCustom(props) {
  const auth = useAuth();
  const profileUrl = auth.user ? `/profile/${auth.user?.id}` : null

  return (
    <Navbar bg={props.bg} variant={props.variant} expand={props.expand} sticky="top">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <BsHeartFill color="red" /> Grok it!
          </Navbar.Brand>
        </LinkContainer>
        {auth.user ? <Navbar.Toggle children={<UserMenu user={auth.user} />} aria-controls="grokit-navbar-nav" /> : <Navbar.Toggle aria-controls="grokit-navbar-nav" />}
        <Navbar.Collapse id="grokit-navbar-nav">
          <Nav>
            {auth.user ? (
                <>
                  <NavDropdown title="Posts and collections" id="posts-nav-dropdown">
                    <NavDropdown.Item href="/dashboard">New post</NavDropdown.Item>
                    <NavDropdown.Item href={profileUrl}>Your posts</NavDropdown.Item>
                    <NavDropdown.Item href="/my-collections">Your collections</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Artists" id="artist-nav-dropdown">
                    <NavDropdown.Item href="/my-artists">Subscribed</NavDropdown.Item>
                    <NavDropdown.Item href="/blocked-artists">Muted</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Account" id="account-nav-dropdown">
                    <NavDropdown.Item href="/settings/general">Settings</NavDropdown.Item>
                    <NavDropdown.Item href="/auth/signout" onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}>Sign out</NavDropdown.Item>
                  </NavDropdown>
                </>
            ) :
                <>
                  <Nav.Item>
                    <LinkContainer to="/auth/signin">
                      <Nav.Link active={false}>Sign in</Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <LinkContainer to="/auth/signup">
                      <Nav.Link active={false}>Sign up</Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
