import {useComments} from "../util/db";
import AddComment from "./AddComment";
import {Alert, ListGroup} from "react-bootstrap";
import {useAuth} from "../util/auth";
import "./Comments.scss"
import Comment from "./Comment";
import BlockedComment from "./BlockedComment";

const Comments = (props) => {
    const auth = useAuth()
    const { post } = props
    const { data: comments } = useComments(post.id)
    return (
        <div className="comments pb-5">
            <h3 style={{fontSize: "1.2em", color: "#ccc", marginTop: "0.5em"}}>Comments</h3>
            {comments && comments.length > 0 ?
                <ListGroup className="mt-2 mb-3">
                    {comments && comments.map((comment, index) => (
                        <ListGroup.Item variant="dark" key={comment.id}>
                          {auth && auth.user && auth.user.blocked?.includes(comment.owner) ? <BlockedComment odd={index%2!==0} /> : <Comment comment={comment} odd={index%2!==0} />}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                :
                <Alert variant="dark">
                    🙊 There are no comments yet.
                </Alert>
            }
            <hr style={{borderColor: "white"}} />
            {auth.user ? <AddComment postId={post.id} user={auth.user} /> : <p><a href="/auth/signin">Sign in to comment</a></p>}
        </div>
    )
}

export default Comments;
