import {blockUser, subscribeToUser, unblockUser, unsubscribeToUser} from "../util/db";
import {Button} from "react-bootstrap";
import {BsFillPersonPlusFill, BsFillPersonXFill, BsFillXCircleFill} from "react-icons/bs";
import React from "react";

const UserRelations = props => {
    const { otherUser, authUser } = props
    if (authUser.id === otherUser) {
        return null
    }
    const subscribe = () => subscribeToUser(authUser.id, otherUser)
    const unsubscribe = () => unsubscribeToUser(authUser.id, otherUser)
    const blockThisUser = () => blockUser(authUser.id, otherUser)
    const unblockThisUser = () => unblockUser(authUser.id, otherUser)
    return (
        <>
            {authUser.blocked && authUser.blocked.includes(otherUser) ?
                <Button variant="warning" onClick={unblockThisUser}>
                    <BsFillPersonXFill />{' '}Stop muting
                </Button>
                :
                <>
                    {authUser.subscribedTo && authUser.subscribedTo.includes(otherUser) ?
                        <Button variant="danger" className="mt-2" onClick={unsubscribe}>
                            <BsFillPersonXFill />{' '}Unsubscribe
                        </Button>
                        :
                        <Button variant="dark" className="mt-2" onClick={subscribe}>
                            <BsFillPersonPlusFill />{' '}Subscribe
                        </Button>
                    }{" "}
                    <Button variant="dark" className="mt-2" onClick={blockThisUser}>
                        <BsFillXCircleFill />{' '}Mute artist
                    </Button>
                </>
            }
        </>
    )
}

export default UserRelations
