import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>Terms of Service</h1>
      <p>
        These Terms of Service govern your use of grokit.art. By accessing grokit.art,
        you agree to abide by these Terms of Service and to comply with all
        applicable laws and regulations that are in force in the United Kingdom.
      </p>
      <p>
        If you do not agree with these Terms of Service, you are prohibited from
        using or accessing grokit.art and from using any other services we
        provide. We reserve the right to review and amend any of these Terms of
        Service at our sole discretion. Upon doing so, we will update this page.
        Any changes to these Terms of Service will take effect immediately from
        the date of publication.
      </p>
      <p>This policy was last updated on 30 September 2022.</p>
      <h2>Limitations of Use</h2>
      <p>
        By using grokit.art, you warrant on behalf of yourself, your users,
        and other parties you represent that you will not:
      </p>
      <ol>
        <li>
          modify, copy, prepare derivative works of, decompile, or reverse
          engineer any materials and software contained on the grokit.art
          website.
        </li>
        <li>
          remove any copyright or other proprietary notations from any materials
          and software on the grokit.art website.
        </li>
        <li>post or comment in an abusive, harmful, hateful or offensive manner</li>
        <li>post images that do not pertain to art works</li>
      </ol>

    </LongContent>
  );
}

export default LegalTerms;
