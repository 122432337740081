import {usePost} from "../util/db";
import "./CollectionThumb.scss"
import {resizeImg} from "../util/images";
import {useEffect, useState} from "react";
const CollectionThumb = props => {
  const { collection, index } = props
  const { data, status } = usePost(collection.posts[index])
  const [ thumb, setThumb ] = useState("")
  useEffect(() => {
    if (data && data.image) {
      setThumb(resizeImg(data.image, 80))
    }
  }, [data])
  return (
    <>
      {status !== "loading" && thumb &&
        <div className={"collection-thumb"} style={{float: "left", backgroundImage: (thumb ? `url(${thumb})` : "")}}>
          <span className={"sr-only"}>{collection.title + " image"}</span>
        </div>
      }
    </>
  )
}

export default CollectionThumb