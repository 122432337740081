import {Col, Row} from "react-bootstrap";
import {BsHeart, BsHeartFill, BsChat, BsFolderFill} from "react-icons/bs";
import React, {useState} from "react";
import {useAuth} from "../util/auth";
import {addLike, removeLike, useComments} from "../util/db";
import "./PostActions.scss"
import {useRouter, Link} from "../util/router";
import CollectionsModal from "./CollectionsModal";

function PostActionBar(props) {
    const [ showCollections, setShowCollections ] = useState(false)
    const handleClose = e => {
        setShowCollections(false)
    }
    const auth = useAuth();
    const router = useRouter()
    const { post } = props
    const { data: comments } = useComments(post.id)
    const toggleLike = (e) => {
        e.preventDefault()
        if (!auth.user) {
            router.push("/auth/signin")
        } else {
            if (post.likedBy.includes(auth.user.id)) {
                removeLike(post.id, auth.user.id)
            } else {
                addLike(post.id, auth.user.id)
            }
        }
    }
    return (
        <>
            <Row xs={3} className="post-actions">
                <Col>
                    <Link className="no-glow bg-transparent btn-outline-transparent" to={"#"} onClick={toggleLike}>
                        {auth.user && post.likedBy.length > 0 && post.likedBy.includes(auth.user.id) ?
                            <BsHeartFill color="red" size="1.5em"/> : <BsHeart color="white" size="1.5em"/>}
                        {post.likedBy && <span className="actions-count">{post.likedBy.length}</span>}
                    </Link>
                </Col>
                <Col>
                    <Link className="bg-transparent btn-outline-transparent" to={"/posts/" + post.id + "/comments"}>
                        <BsChat color="white" size="1.5em"/>
                        <span className="actions-count">{comments ? comments.length : 0}</span>
                    </Link>
                </Col>
                <Col>
                    <Link className="bg-transparent btn-outline-transparent" to={"/posts/" + post.id}>
                        <BsFolderFill color="white" size="1.5em" onClick={e => {
                            e.preventDefault()
                            if (!auth.user) {
                                router.push("/auth/signin")
                            } else {
                                setShowCollections(true)
                            }
                        }} />
                        <span className="actions-count">0</span>
                    </Link>
                </Col>
            </Row>
            {auth.user &&
                <CollectionsModal
                    show={showCollections}
                    setShow={setShowCollections}
                    user={auth.user.id}
                    handleClose={handleClose}
                    postId={post.id}
                />
            }
        </>
    );
}

export default PostActionBar