import React, {useCallback, useEffect, useState} from "react";
import Meta from "./../components/Meta";
import Post from "../components/Post";
import {usePost} from "../util/db";
import Spinner from "react-bootstrap/Spinner";
import {useRouter} from "../util/router";
import {Container} from "react-bootstrap";
import Comments from "../components/Comments";


function PostPage(props) {
    const router = useRouter();
    const [pageTitle, setPageTitle] = useState("Post")
    const {
        data: post,
        status
    } = usePost(router.query.postId)

    useEffect(() => {
        if(post && post.title) {
            setPageTitle(post.title)
        }
    }, [post])
    const scrollToComments = useCallback(node => {
        if(node && router.query.element === "comments") {
            node.scrollIntoView({behavior: "smooth"})
        }
    }, [router.query])
    return (
        <>
            <Meta title={pageTitle} />
            <Container className={"mt-1"}>
            {status === "loading" && (

                <Spinner animation="border" variant="primary">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            )}

            {status !== "loading" && post && (
                <>
                    <Post post={post} commentRef={scrollToComments} />
                    <hr ref={scrollToComments} style={{borderColor: "black"}} />
                    <Comments post={post} />
                </>
            )}
            </Container>
        </>
    )
}

export default PostPage
