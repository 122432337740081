import React from "react";
import Meta from "./../components/Meta";
import {useAuth} from "../util/auth";
import PublicPosts from "../components/PublicPosts";
import FeedPosts from "../components/FeedPosts";
import {useCategories} from "../util/db";

function IndexPage(props) {
  const auth = useAuth()
  const { data: categories } = useCategories()
  return (
    <>
      <Meta />
      {auth.user ? <FeedPosts categories={categories} authUser={auth.user} /> : <PublicPosts categories={categories} />}
    </>
  );
}

export default IndexPage;
