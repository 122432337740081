import React from "react";
import Meta from "./../components/Meta";
import {requireAuth, useAuth} from "../util/auth";
import {useCategories, usePostsByOwner} from "../util/db";
import Posts from "../components/Posts";
import Loading from "../components/Loading";
import {Alert, Container} from "react-bootstrap";

function MyPosts(props) {
    const { data: categories } = useCategories()
    const auth = useAuth();
    const { data, status } = usePostsByOwner(auth.user.id)
  return (
    <>
      <Meta title="Your posts" />
        {status === "loading" &&
          <Container><Loading /></Container>
        }
        {status !== "loading" && data && categories &&
          <>
            {data.length > 0 ?
              <Posts withSlideShow={true} title={"Your posts"} categories={categories} posts={data} />
              :
              <Alert variant={"info"}>You currently have no posts.</Alert>
            }
          </>
          }
    </>
  );
}

export default requireAuth(MyPosts);
