import React from "react";
import Meta from "./../components/Meta";
import { requireAuth } from "./../util/auth";
import AddImage from "../components/AddImage";

function DashboardPage(props) {
  return (
    <>
      <Meta title="New post" />
      <AddImage />
    </>
  );
}

export default requireAuth(DashboardPage);
