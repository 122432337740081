import {Col, Row} from "react-bootstrap";
import Owner from "./Owner";
import "./Comments.scss"
const Comment = (props) => {
    const { comment, odd } = props
    return (
        <Row>
            <Col style={{textAlign: "center", width: "78px"}}>
                {!odd && <Owner ctx={"comments"} owner={comment.owner} />}
            </Col>
            <Col xs={8}>
                <div  className={odd ? "bubble odd" : "bubble even"}>{comment.comment}</div>
            </Col>
            <Col style={{textAlign: "center", width: "78px"}}>
                {odd && <Owner ctx={"comments"} owner={comment.owner} />}
            </Col>
        </Row>
    )
}

export default Comment;