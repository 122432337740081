import React from "react";
import * as PropTypes from "prop-types";
import Post from "./Post";
import {Alert, Container} from "react-bootstrap";
import {usePostsInList} from "../util/db";
import Loading from "./Loading";
import PostFeedItem from "./PostFeedItem";

Post.propTypes = {posts: PropTypes.any};

function PostsList(props) {
  const { postIds, pageIndex, collectionId } = props
  const { data, status } = usePostsInList(postIds, collectionId + pageIndex)

  return (
      <Container>
        {status === "loading" && <Loading />}
        {status !== "loading" && data && data.length > 0 ? data.map((post, index) =>
            <PostFeedItem key={"post-in-list" + post.id} post={post}/>
          )
          :
          <Alert variant="dark">
            This is an empty collection.
          </Alert>
        }
      </Container>
  )
}

export default PostsList
