import Avatar from "./Avatar";
import React from "react";
import "./PostOwner.scss";
import {Link} from "../util/router";

const User = props => {
    const { user, hideName } = props
    const size = hideName ? "24px" : "32px"
    return (
        <div>
            <Link to={"/profile/" + user.id } style={{color: "white"}}>
                <>
                    {user.photoURL ?
                        <Avatar src={user.photoURL} alt={user.name} size={size} />
                        :
                        <span className="avatar-placeholder">{user.name[0]}</span>
                    }
                    {!hideName && user.username.length > 0 ?
                        <span className="username">@{user.username.toString().toLowerCase()}</span>
                        : !hideName && <span className="name-display">{user.name}</span>
                    }
                </>
            </Link>
        </div>
    )
}

export default User