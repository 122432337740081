import Card from "react-bootstrap/Card";
import React from "react";
import PostActionBar from "./PostActionBar";
import "./Post.scss";
import {resizeImg} from "../util/images";
import PostHeader from "./PostHeader";

const Post = props => {
    const { post } = props
    return (
        <>
            <Card text="dark" className="text-decoration-none mb-2" style={{border: "none"}}>
                <Card.Header className="tight-header">
                    <PostHeader post={post} />
                </Card.Header>
                <Card.Img src={resizeImg(post.image, 1200)} alt={post.title} variant="top"/>
                <Card.Footer className="tight-header">
                    {post.title && <h2 style={{color: "#ccc", marginTop: "0.5em", marginBottom: "1em", fontSize: "1.5em"}}>“{post.title}”</h2>}
                    <PostActionBar post={post} />
                </Card.Footer>
            </Card>
        </>

    )
}

export default Post