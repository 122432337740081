import React, {useState} from "react";
import * as PropTypes from "prop-types";
import Post from "./Post";
import {Container, ListGroup, Alert, Button} from "react-bootstrap";
import {deleteCollection, useCollections} from "../util/db";
import "./Collections.scss"
import AddCollection from "./AddCollection";
import Modal from "react-bootstrap/Modal";
import CollectionListItem from "./CollectionListItem";

Post.propTypes = {posts: PropTypes.any};

function Collections(props) {
  const { user, title, viewer } = props
  const { data: collections } = useCollections(user)
  const [ activeCollection, setActiveCollection ] = useState(null)
  const handleClose = e => {
    setActiveCollection(null)
  }
  const openDelete = collection => e => {
    e.preventDefault()
    e.stopPropagation()
    setActiveCollection(collection)
  }
  const deleteThisCollection = collection => e => {
    e.preventDefault();
    if (collection) {
      deleteCollection(collection.id)
        .then(() => setActiveCollection(null))
    }
  }
  return (
    <Container className={"mt-1 p-0"}>
      <h2 className="my-3">{title}</h2>
      {collections && collections.length > 0 ?
        <>
          <ListGroup className="collections list-group-flush p-0">
            {collections.map(collection => (
              <ListGroup.Item className={"px-0"} variant={"dark"} key={collection.id}>
                <CollectionListItem isOwner={viewer === user} collection={collection} onClick={openDelete(collection)}/>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Modal
            show={activeCollection !== null}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Delete “{activeCollection && activeCollection.title}”?</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
              <p>You cannot undo the deletion of a collection, deleting collections does not delete any posts.</p>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>{' '}
              {activeCollection && <Button variant="success" onClick={deleteThisCollection(activeCollection)}>
                Delete collection
              </Button>}
            </Modal.Body>
          </Modal>
        </>
        :
        <Alert variant="info">
          You do not have any collections. You can create new collections as you save posts.
        </Alert>
      }

      {viewer === user && <AddCollection postId={null} user={user} setShow={() => {}} heading={"Create new empty collection"} />}
    </Container>
  )
}

export default Collections;
