import React from "react";
import "./Slideshow.scss"
import {usePostsInList} from "../util/db";

const delay = 2500;

function SlidePage(props) {
  const { page, pageIndex, collectionId } = props
  const { data } = usePostsInList(page, collectionId + "-" + pageIndex)
  return (
    <>
      {data && data.map((post, index) => (
      <div
        key={"slide-post-" + post.id}
        className="slide"
        style={{ backgroundImage: "url(" + post.image + ")" }}
      ></div>))}
    </>
  )
}

function SlideshowCollector(props) {
  const { pages, collection } = props
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === collection.posts.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index, collection.posts.length]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {pages.map((page, pageIndex) => (
          <SlidePage key={collection.id + "-" + pageIndex} collectionId={collection.id} page={page} pageIndex={pageIndex} slideIndex={index} />
        ))}
      </div>

      <div className="slideshowDots">
        {collection.posts.length > 0 && collection.posts.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default SlideshowCollector
