import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {createCollection} from "../util/db";

const AddCollection = (props) => {
  const { postId, user, setShow, heading } = props
  const [ title, setTitle ] = useState('')
  const handleChange = fn => e => {
    fn(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const posts = postId ? [postId] : []
    if (user && title) {
      createCollection({
        title,
        posts,
        owner: user,
        isDeleted: false,
        public: true
      }).then(() => setShow(false))
    }
  }
  return (
      <Form className="mt-3 mb-3" onSubmit={handleSubmit}>
        <h4 className={"y-3"}>{heading}</h4>
        <Form.Group controlId="title">
          <Form.Label>New collection name</Form.Label>
          <Form.Control className="input-comment" type="text" onChange={handleChange(setTitle)} value={title} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Create new collection
        </Button>
      </Form>
  )
}

export default AddCollection
